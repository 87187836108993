// Override default variables before the import
$body-bg: #fff;




$primary:       #8dc63f;
$secondary:     #ffbc00;

$theme-colors: ();

$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@import '~bootstrap/scss/bootstrap.scss';
@import '~compass-sass-mixins/lib/compass';

$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #222;
}

#body-container {
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App-header {
  background: $primary;
}

.App-logo {
  width: 420px;
  max-width: 85%;
}

.btn {
   &.btn-primary {
     color: #fff;
     text-transform: uppercase;
   }
}

.footer {
  color: #ccc;

  a {
    color: #fff;
  }
}

.round {
  @include border-radius(100%);
}

#callout {
  background: #e4e4e4;

  a {
    color: darken($primary, 20%);
    font-weight: bold;
  }
}

.profile-box.row {
  background: #eee;
  @include media-breakpoint-up('sm') {
    background: none;
  }
}